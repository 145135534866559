import {memo} from 'react';
import {Card, CardBody, CardHeader, Col, Row} from 'reactstrap';

import {FormikInput, FormikPhoneInput} from '@reasoncorp/kyber-js';

type Props = {
  className?: string
}

const ProfileCard = ({className}: Props) => {
  return (
    <Card className={className}>
      <CardHeader className="bg-secondary text-uppercase text-white">Profile</CardHeader>
      <CardBody>
        <Row>
          <Col lg="3">
            <FormikInput name="firstName"
                         labelText="First Name"
                         maxLength={50}
                         aria-required="true"/>
          </Col>
          <Col lg="3">
            <FormikInput name="lastName"
                         labelText="Last Name"
                         maxLength={50}
                         aria-required="true"/>
          </Col>
          <Col lg="3">
            <FormikPhoneInput name="phoneNumber"
                              labelText="Phone Number"
                              extensionLength={5}
                              aria-required="true"/>
          </Col>
          <Col lg="3">
            <FormikInput name="username"
                         labelText="Email Address"
                         aria-required="true"/>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default memo(ProfileCard);