import {memo} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {ButtonIcon, User} from '@reasoncorp/kyber-js';
import branding from '../branding';

type Props = {
  user: User
  readOnly: boolean
  onSettingsClick: (userId: number) => void
}

const UsersTableRow = ({
                         user,
                         readOnly,
                         onSettingsClick
                       }: Props) => {
  const certifiedIcon = user.certifiedUser ? 'award' : 'times';
  const certifiedIconClassName = user.certifiedUser ? 'text-primary' : 'text-secondary';
  const certifiedIconAltText = user.certifiedUser ? 'Certified User' : 'Uncertified User';
  const statusIcon = user.accountStatusOk ? 'check-circle' : 'ban';
  const stateIconClassName = user.accountStatusOk ? 'text-success' : 'text-danger';
  const stateIconAltText = user.accountStatusOk ? 'Active Status' : 'Inactive Status';

  return (
    <tr>
      <td className="align-middle">{user.firstName}</td>
      <td className="align-middle">{user.lastName}</td>
      <td className="align-middle">{user.username}</td>
      {branding.certificationLevels.length !== 0 &&
      <td className="align-middle text-center">
        <FontAwesomeIcon className={certifiedIconClassName}
                         icon={certifiedIcon}
                         title={certifiedIconAltText}
                         aria-label={`${user.firstName} ${user.lastName} certified user status`}/>
      </td>
      }
      <td className="align-middle text-center">
        <FontAwesomeIcon className={stateIconClassName}
                         icon={statusIcon}
                         title={stateIconAltText}
                         aria-label={`${user.firstName} ${user.lastName} account status`}/>
      </td>
      {!readOnly &&
      <td className="align-middle text-center">
        <ButtonIcon icon="cog"
                    title={`Edit ${user.firstName} ${user.lastName}`}
                    ariaLabel={`Edit ${user.firstName} ${user.lastName}`}
                    className="text-primary"
                    onClick={() => onSettingsClick(user.id)}/>
      </td>
      }
    </tr>
  );
};

export default memo(UsersTableRow);