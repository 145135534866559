import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Badge, Button, Form, FormGroup, FormText} from 'reactstrap';
import {Formik, FormikHelpers} from 'formik';

import {FormikCheckboxGroup, FormikInput} from '@reasoncorp/kyber-js';

import * as messages from '../../messages';
import {authenticationApi} from '../../api';
import {useSsoAppContext} from '../../hooks';
import {VerifyDeviceLoginFormFields} from '../../types';
import {verifyDeviceLoginFormSchema} from '../../schema';
import config from '../../config';

const VerifyDeviceLoginForm = () => {
  const navigate = useNavigate();
  const {setCurrentUser} = useSsoAppContext();
  const errorEl = useRef<HTMLParagraphElement>(null);
  const [serverError, setServerError] = useState(messages.UNKNOWN_DEVICE);
  const initialValues: VerifyDeviceLoginFormFields = useMemo(() => ({
    code: '',
    remember: false
  }), []);

  const handleSubmit = useCallback(async (values: VerifyDeviceLoginFormFields,
                                          formikHelpers: FormikHelpers<VerifyDeviceLoginFormFields>) => {
    try {
      await authenticationApi.verifyDeviceLogin(values);
      const currentUser = await authenticationApi.currentUser();
      setCurrentUser(currentUser);
      navigate('/dashboard');
    } catch (error: any) {
      const errorWithType = error as {message?: string};
      formikHelpers.setSubmitting(false);
      // Fetch will throw a TypeError if there was a network issue
      if (error instanceof TypeError) {
        setServerError(messages.UNABLE_TO_CONNECT_SERVICE);
      } else if (errorWithType.message) {
        setServerError(errorWithType.message);
      } else {
        setServerError(messages.UNKNOWN_LOGIN_FAILURE);
      }
      // Focus the error message as part of ADA requirements
      errorEl?.current?.focus();
    }
  }, [
    navigate,
    setCurrentUser
  ]);

  const loginErrorClassName = useMemo(() => {
    return `text-center text-danger p-2 ${serverError ? 'login-error' : ''}`;
  }, [
    serverError
  ]);

  const headerClassName = useMemo(() => {
    return `h4 mt-5 text-center ${serverError ? 'mb-3' : 'mb-4'}`;
  }, [
    serverError
  ]);

  useEffect(() => {
    // Focus the error message as part of ADA requirements
    errorEl?.current?.focus();
  }, []);

  return (
    <div className="VerifyDeviceLoginForm">
      <h3 className={headerClassName}>
        Verify Device
        {config.envName !== 'prod' &&
          <Badge color="warning" className="text-uppercase ml-2">
            {config.envName}
          </Badge>
        }
      </h3>
      <p className={loginErrorClassName}
         ref={errorEl}
         tabIndex={0}>
        {serverError}
      </p>
      <Formik initialValues={initialValues}
              validationSchema={verifyDeviceLoginFormSchema}
              onSubmit={handleSubmit}>
        {formikProps => (
          <Form onSubmit={formikProps.handleSubmit}>
            <FormikInput name="code"
                         labelText="Verification Code"/>
            <FormText color="muted mb-2">
              It is not recommended to remember devices that are not personal (e.g. public computer).
            </FormText>
            <FormikCheckboxGroup checkboxes={[
              {name: 'remember', labelText: 'Remember this device.'}
            ]}/>
            <FormGroup className="text-center">
              <Button type="submit"
                      color="primary"
                      disabled={!formikProps.dirty || !formikProps.isValid || formikProps.isSubmitting}>
                Login
              </Button>
            </FormGroup>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default VerifyDeviceLoginForm;