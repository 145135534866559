import {memo} from 'react';
import {Button, Card, CardBody, CardFooter, CardHeader, Col, Row} from 'reactstrap';

type Props = {
  title: string
  description: string
  onDownloadClick: (() => void)
}

const ReportCard = ({
                      title,
                      description,
                      onDownloadClick
                    }: Props) => {
  return (
    <Col xs="12" sm="6" md="4" lg="4" className="mb-4">
      <Card className="h-100"
            title={title}
            tabIndex={-1}
            role="region"
            id={title}>
        <CardHeader className="bg-secondary text-white">
          {title}
        </CardHeader>
        <CardBody>
          <Row className="mb-4">
            <Col>
              {description}
            </Col>
          </Row>
        </CardBody>
        <CardFooter>
          <Row>
            <Col className="d-flex justify-content-end col-12">
              <Button color="primary"
                      className="mr-1"
                      aria-label={`${title} Download Button`}
                      onClick={onDownloadClick}>
                Download
              </Button>
            </Col>
          </Row>
        </CardFooter>
      </Card>
    </Col>);
};

export default memo(ReportCard);
