import {useCallback, useMemo, useRef, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {Badge, Button, Form, FormGroup} from 'reactstrap';
import {Formik, FormikHelpers} from 'formik';

import {FormikInput, FormikPasswordInput} from '@reasoncorp/kyber-js';

import * as messages from '../../messages';
import {authenticationApi} from '../../api';
import {useSsoAppContext} from '../../hooks';
import {LoginFormFields} from '../../types';
import {loginFormSchema} from '../../schema';
import ForgotPasswordModal from './ForgotPasswordModal';
import branding from '../../branding';
import config from '../../config';

type Props = {
  onUnknownDevice: () => void
  onPasswordExpired: (lastCredentials: {username: string, password: string}) => void
}

const LoginForm = ({
                     onUnknownDevice,
                     onPasswordExpired
                   }: Props) => {
  const navigate = useNavigate();
  const {setCurrentUser} = useSsoAppContext();
  const errorEl = useRef<HTMLParagraphElement>(null);
  const [serverError, setServerError] = useState('');
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);

  const redirectParamValue = useMemo(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    return urlSearchParams.get('redirect');
  }, []);

  const loginFormInitialValues: LoginFormFields = useMemo(() => ({
    username: '',
    password: ''
  }), []);

  const handleSubmit = useCallback(async (values: LoginFormFields,
                                          formikHelpers: FormikHelpers<LoginFormFields>) => {
    try {
      await authenticationApi.login(values.username, values.password);
      // If a redirect URL is present attempt to parse it and go to it, otherwise go to the dashboard
      if (redirectParamValue) {
        try {
          const redirectUrl = new URL(unescape(redirectParamValue));
          // If redirect protocol is not the same as server redirect to dashboard to prevent immediate logout scenario
          if (redirectUrl.protocol !== window.location.protocol) {
            redirectUrl.protocol = window.location.protocol;
          }
          window.location.href = redirectUrl.toString();
        } catch (error) {
          // If redirect URL can't be parsed go to the dashboard
          const currentUser = await authenticationApi.currentUser();
          setCurrentUser(currentUser);
          navigate('/dashboard', {replace: true});
        }
      } else {
        const currentUser = await authenticationApi.currentUser();
        setCurrentUser(currentUser);
        navigate('/dashboard', {replace: true});
      }
    } catch (error: any) {
      const errorWithType = error as {message?: string, exception?: string | string[]};
      formikHelpers.setSubmitting(false);
      // Fetch will throw a TypeError if there was a network issue
      if (error instanceof TypeError) {
        setServerError(messages.UNABLE_TO_CONNECT_SERVICE);
      } else if (errorWithType.exception && errorWithType.exception.includes('RememberMeAuthenticationException')) {
        onUnknownDevice();
      } else if (errorWithType.exception && errorWithType.exception.includes('CredentialsExpiredException')) {
        onPasswordExpired({username: values.username, password: values.password});
      } else if (error.message) {
        setServerError(error.message);
      } else {
        setServerError(messages.UNKNOWN_LOGIN_FAILURE);
      }
      // Focus the error message as part of ADA requirements
      errorEl?.current?.focus();
    }
  }, [
    navigate,
    onPasswordExpired,
    onUnknownDevice,
    redirectParamValue,
    setCurrentUser
  ]);

  const loginErrorClassName = useMemo(() => {
    return `text-center text-danger p-2 ${serverError ? 'login-error' : ''}`;
  }, [
    serverError
  ]);

  const headerClassName = useMemo(() => {
    return `h4 mt-5 text-center ${serverError ? 'mb-3' : 'mb-4'}`;
  }, [
    serverError
  ]);

  return (
    <div className="LoginForm mb-4">
      <h3 className={headerClassName}>
        Login {config.envName !== 'prod' &&
        <Badge color="warning" className="text-uppercase mt-0">
          {config.envName}
        </Badge>}
      </h3>
      <p className={loginErrorClassName}
         ref={errorEl}
         tabIndex={0}>
        {serverError}
      </p>
      <Formik initialValues={loginFormInitialValues}
              validationSchema={loginFormSchema}
              onSubmit={handleSubmit}>
        {formikProps => (
          <Form onSubmit={formikProps.handleSubmit}>
            <FormikInput name="username"
                         labelText="Email Address"
                         autoComplete="username"
                         aria-required="true"/>
            <FormikPasswordInput name="password"
                                 labelText="Password"
                                 autoComplete="current-password"
                                 aria-required="true"/>
            <FormGroup className="text-center">
              <Button type="submit"
                      color="primary"
                      block
                      disabled={!formikProps.dirty || !formikProps.isValid || formikProps.isSubmitting}>
                Login
              </Button>
              <Button color="link"
                      block
                      onClick={() => setShowForgotPasswordModal(true)}>
                Forgot Password
              </Button>
              {branding.name === 'MISUITE' && <Link to="/faq"
                                                    target="_blank">
                Login Assistance (FAQ)
              </Link>}
            </FormGroup>
          </Form>
        )}
      </Formik>
      <ForgotPasswordModal isOpen={showForgotPasswordModal}
                           onToggle={() => setShowForgotPasswordModal(false)}/>
    </div>
  );
};

export default LoginForm;