import {AppConfig, Duration, DurationType} from '@reasoncorp/kyber-js';
import branding from './branding';

const miSuiteAppConfig: AppConfig = {
  'localhost': {
    // Better logic to allow for a machine not running the dev server to resolve the API url better
    envName: 'dev',
    apiHost: `${window.location.protocol}//${window.location.hostname}:50101`,
    sso: {
      webUrl: `${window.location.protocol}//${window.location.hostname}:50100`,
      apiUrl: `${window.location.protocol}//${window.location.hostname}:50101`,
      lastActivityCookieName: 'last_activity_localhost',
      lastActivityCookieDomain: 'localhost',
      maxSession: Duration.of(15, DurationType.MINUTES),
      sessionWarningAt: Duration.of(2, DurationType.MINUTES)
    }
  },
  'sso.qa.misuite.app': {
    envName: 'qa',
    apiHost: 'https://sso-api.qa.misuite.app',
    sso: {
      webUrl: 'https://sso.qa.misuite.app',
      apiUrl: 'https://sso-api.qa.misuite.app',
      lastActivityCookieName: 'last_activity_qa',
      lastActivityCookieDomain: 'qa.misuite.app',
      maxSession: Duration.of(15, DurationType.MINUTES),
      sessionWarningAt: Duration.of(2, DurationType.MINUTES)
    }
  },
  'sso.uat.misuite.app': {
    envName: 'uat',
    apiHost: 'https://sso-api.uat.misuite.app',
    sso: {
      webUrl: 'https://sso.uat.misuite.app',
      apiUrl: 'https://sso-api.uat.misuite.app',
      lastActivityCookieName: 'last_activity_uat',
      lastActivityCookieDomain: 'uat.misuite.app',
      maxSession: Duration.of(15, DurationType.MINUTES),
      sessionWarningAt: Duration.of(2, DurationType.MINUTES)
    }
  },
  'sso.misuite.app': {
    envName: 'prod',
    apiHost: 'https://sso-api.misuite.app',
    sso: {
      webUrl: 'https://sso.misuite.app',
      apiUrl: 'https://sso-api.misuite.app',
      lastActivityCookieName: 'last_activity_prod',
      lastActivityCookieDomain: 'misuite.app',
      maxSession: Duration.of(15, DurationType.MINUTES),
      sessionWarningAt: Duration.of(2, DurationType.MINUTES)
    }
  }
};

const prosuiteAppConfig: AppConfig = {
  'localhost': {
    // Better logic to allow for a machine not running the dev server to resolve the API url better
    envName: 'dev',
    apiHost: `${window.location.protocol}//${window.location.hostname}:50101`,
    sso: {
      webUrl: `${window.location.protocol}//${window.location.hostname}:50100`,
      apiUrl: `${window.location.protocol}//${window.location.hostname}:50101`,
      lastActivityCookieName: 'last_activity_localhost',
      lastActivityCookieDomain: 'localhost',
      maxSession: Duration.of(30, DurationType.MINUTES),
      sessionWarningAt: Duration.of(2, DurationType.MINUTES)
    }
  },
  'sso.qa.prosuite.app': {
    envName: 'qa',
    apiHost: 'https://sso-api.qa.prosuite.app',
    sso: {
      webUrl: 'https://sso.qa.prosuite.app',
      apiUrl: 'https://sso-api.qa.prosuite.app',
      lastActivityCookieName: 'last_activity_qa',
      lastActivityCookieDomain: 'qa.prosuite.app',
      maxSession: Duration.of(30, DurationType.MINUTES),
      sessionWarningAt: Duration.of(2, DurationType.MINUTES)
    }
  },
  'sso.uat.prosuite.app': {
    envName: 'uat',
    apiHost: 'https://sso-api.uat.prosuite.app',
    sso: {
      webUrl: 'https://sso.uat.prosuite.app',
      apiUrl: 'https://sso-api.uat.prosuite.app',
      lastActivityCookieName: 'last_activity_uat',
      lastActivityCookieDomain: 'uat.prosuite.app',
      maxSession: Duration.of(30, DurationType.MINUTES),
      sessionWarningAt: Duration.of(2, DurationType.MINUTES)
    }
  },
  'sso.prosuite.app': {
    envName: 'prod',
    apiHost: 'https://sso-api.prosuite.app',
    sso: {
      webUrl: 'https://sso.prosuite.app',
      apiUrl: 'https://sso-api.prosuite.app',
      lastActivityCookieName: 'last_activity_prod',
      lastActivityCookieDomain: 'prosuite.app',
      maxSession: Duration.of(30, DurationType.MINUTES),
      sessionWarningAt: Duration.of(2, DurationType.MINUTES)
    }
  }
};

const config = branding.name === 'PROSUITE' ? prosuiteAppConfig : miSuiteAppConfig;
export default config[window.location.hostname] || config.localhost;