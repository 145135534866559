import {JurisdictionRole, User} from '@reasoncorp/kyber-js';

import {ssoApi} from './apiUtils';
import {AddUserFormFields, EditUserFormFields, UserProfileFormFields} from '../types';

export const currentUser = ssoApi.currentUser;

export const users = (): Promise<User[]> => ssoApi.restApiHelper.getWithJsonResponse('users');

export const user = (id: number): Promise<User> => ssoApi.restApiHelper.getWithJsonResponse(`users/${id}`);

export const create = (userCreateRequest: AddUserFormFields): Promise<User> => ssoApi.restApiHelper.postWithJsonResponse(
  'users',
  {
    body: JSON.stringify(userCreateRequest)
  }
);

export const checkExistingDeletedUser = (userCreateRequest: AddUserFormFields): Promise<boolean> => ssoApi.restApiHelper.postWithJsonResponse(
  'users/check-existing',
  {
    body: JSON.stringify(userCreateRequest)
  }
);

export const checkJurisdictionRoleMax = (userId: number,
                                         jurisdictionRole: JurisdictionRole): Promise<boolean> => ssoApi.restApiHelper.postWithJsonResponse(
  `users/${userId}/check-jurisdiction-role-max`,
  {
    body: JSON.stringify(jurisdictionRole)
  }
);

export const update = (userId: number, userEditRequest: EditUserFormFields): Promise<User> => ssoApi.restApiHelper.putWithJsonResponse(
  `users/${userId}`,
  {
    body: JSON.stringify(userEditRequest)
  }
);

export const updateUserProfile = (userProfileEditRequest: UserProfileFormFields): Promise<User> => ssoApi.restApiHelper.putWithJsonResponse(
  'users/current-user',
  {
    body: JSON.stringify(userProfileEditRequest)
  }
);

export const deleteUser = (userId: number): Promise<void> => {
  return ssoApi.restApiHelper.deleteWithJsonResponse(`users/${userId}`);
};