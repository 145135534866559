import {ssoApi, ssoConfig} from './apiUtils';
import {NewPasswordLoginFormFields, ResetPasswordFormFields, VerifyDeviceLoginFormFields} from '../types';

export const verifyDeviceLogin = async (verifyDeviceLoginFields: VerifyDeviceLoginFormFields): Promise<string> => {
  const jwt = await ssoApi.restApiHelper.postWithTextResponse(
    'client-auth/verify-device-login',
    {
      body: JSON.stringify(verifyDeviceLoginFields)
    }, {
      // The caller is responsible for handling any unauthorized failures in this case.
      // In this case the only caller is the SSO Login component.
      suppressUnauthorizedHandler: true
    }
  );
  localStorage.setItem(ssoConfig.jwtKey, jwt);
  return jwt;
};

export const newPasswordLogin = async (newPasswordLoginFormFields: NewPasswordLoginFormFields): Promise<string> => {
  const jwt = await ssoApi.restApiHelper.postWithTextResponse(
    'client-auth/new-password-login',
    {
      body: JSON.stringify(newPasswordLoginFormFields)
    }, {
      // The caller is responsible for handling any unauthorized failures in this case.
      // In this case the only caller is the SSO Login component.
      suppressUnauthorizedHandler: true
    }
  );
  localStorage.setItem(ssoConfig.jwtKey, jwt);
  return jwt;
};

export const resetPasswordLogin = async (resetPasswordFormFields: ResetPasswordFormFields): Promise<string> => {
  const jwt = await ssoApi.restApiHelper.postWithTextResponse(
    'client-auth/reset-password-login',
    {
      body: JSON.stringify(resetPasswordFormFields)
    }, {
      // The caller is responsible for handling any unauthorized failures in this case.
      // In this case the only caller is the SSO Login component.
      suppressUnauthorizedHandler: true
    }
  );
  localStorage.setItem(ssoConfig.jwtKey, jwt);
  return jwt;
};

export const login = ssoApi.login;
export const loginFromRefreshToken = ssoApi.loginFromRefreshToken;
export const currentUser = ssoApi.currentUser;
export const signOut = ssoApi.signOut;
export const redirectToLogin = ssoApi.redirectToLogin;
export const monitorSession = ssoApi.monitorSession;