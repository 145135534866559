import {ReactNode, useMemo, useState} from 'react';
import {findIndex, sortBy} from 'lodash';

import {
  Jurisdiction,
  JurisdictionRole,
  MiSuiteJurisdiction,
  MiSuiteRole,
  Role,
  ssoUtils,
  User
} from '@reasoncorp/kyber-js';

import {SsoAppContext, SsoAppContextType} from './SsoAppContext';

type Props = {
  children: ReactNode
}

const SsoAppProvider = ({children}: Props) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [jurisdictionRoles, setJurisdictionRoles] = useState<JurisdictionRole[]>([]);

  const roleJurisdictionMappings = useMemo(
    () => {
      let roleJurisdictionMappings: {
        role: Role,
        jurisdictions: Jurisdiction[]
      }[] = [];
      jurisdictionRoles.forEach(jurisdictionRole => {
        const index = findIndex(roleJurisdictionMappings, roleJurisdictionMapping => roleJurisdictionMapping.role.id === jurisdictionRole.role.id);
        if (index === -1) {
          roleJurisdictionMappings.push({
            role: jurisdictionRole.role,
            jurisdictions: [jurisdictionRole.jurisdiction]
          });
        } else {
          roleJurisdictionMappings[index].jurisdictions.push(jurisdictionRole.jurisdiction);
        }
      });
      roleJurisdictionMappings = sortBy(roleJurisdictionMappings, 'role.displayName');
      roleJurisdictionMappings.forEach(roleJurisdictionMapping => {
        sortBy(roleJurisdictionMapping.jurisdictions, 'displayName');
      });
      return roleJurisdictionMappings;
    },
    [jurisdictionRoles]
  );

  const value: SsoAppContextType = useMemo(() => ({
    currentUser,
    setCurrentUser,
    isAdmin: ssoUtils.hasJurisdictionAndRole(currentUser, MiSuiteJurisdiction.SSO_APP, MiSuiteRole.ADMIN),
    isCsr: ssoUtils.hasJurisdictionAndRole(currentUser, MiSuiteJurisdiction.SSO_APP, MiSuiteRole.CSR),
    isAuditor: ssoUtils.hasJurisdictionAndRole(currentUser, MiSuiteJurisdiction.SSO_APP, MiSuiteRole.AUDITOR),
    isMegAdmin: ssoUtils.hasJurisdictionAndRole(currentUser, MiSuiteJurisdiction.MEG_APP, MiSuiteRole.ADMIN),
    isCertsAdmin: ssoUtils.hasJurisdictionAndRole(currentUser, MiSuiteJurisdiction.CERTS_APP, MiSuiteRole.ADMIN),
    isCertifiedUser: currentUser ? currentUser.certifiedUser : false,
    hasReportAccess: ssoUtils.hasJurisdictionAndRole(currentUser, MiSuiteJurisdiction.SSO_APP, MiSuiteRole.ADMIN) ||
      ssoUtils.hasJurisdictionAndRole(currentUser, MiSuiteJurisdiction.SSO_APP, MiSuiteRole.AUDITOR) ||
      ssoUtils.hasJurisdictionAndRole(currentUser, MiSuiteJurisdiction.PA_660_APP, MiSuiteRole.ADMIN) ||
      ssoUtils.hasJurisdictionAndRole(currentUser, MiSuiteJurisdiction.MEG_APP, MiSuiteRole.ADMIN) ||
      ssoUtils.hasJurisdictionAndRole(currentUser, MiSuiteJurisdiction.CERTS_APP, MiSuiteRole.ADMIN),
    hasMiMessagingAuditReportAccess: ssoUtils.hasJurisdictionAndRole(currentUser, MiSuiteJurisdiction.SSO_APP, MiSuiteRole.ADMIN) ||
      ssoUtils.hasJurisdictionAndRole(currentUser, MiSuiteJurisdiction.PA_660_APP, MiSuiteRole.ADMIN) ||
      ssoUtils.hasJurisdictionAndRole(currentUser, MiSuiteJurisdiction.MEG_APP, MiSuiteRole.ADMIN) ||
      ssoUtils.hasJurisdictionAndRole(currentUser, MiSuiteJurisdiction.CERTS_APP, MiSuiteRole.ADMIN),
    jurisdictionRoles,
    setJurisdictionRoles,
    roleJurisdictionMappings
  }), [
    currentUser,
    jurisdictionRoles,
    roleJurisdictionMappings
  ]);

  return (
    <SsoAppContext.Provider value={value}>
      {children}
    </SsoAppContext.Provider>
  );
};

export default SsoAppProvider;