import {memo, useCallback} from 'react';
import {useNavigate} from 'react-router-dom';

import {CustomTable, User} from '@reasoncorp/kyber-js';
import {UsersTableRow} from './index';
import branding from '../branding';
import * as messages from '../messages';

type Props = {
  users: User[]
  readOnly: boolean
  resultLimit: number | null
}

const UsersTable = ({
                      users,
                      readOnly,
                      resultLimit
                    }: Props) => {
  const navigate = useNavigate();

  const handleSettingsClick = useCallback(
    (userId: number) => navigate(`/users/${userId}`),
    [navigate]
  );

  const buildHeaders = () => {
    const headersToUse: {title: string, sortKey?: string, className: string}[] = [
      {title: 'First Name', sortKey: 'firstName', className: 'text-nowrap'},
      {title: 'Last Name', sortKey: 'lastName', className: 'text-nowrap'},
      {title: 'Email Address', sortKey: 'username', className: 'text-nowrap'}
    ];
    if (branding.certificationLevels.length !== 0) {
      headersToUse.push({title: 'Certified', sortKey: 'certifiedUser', className: 'text-center'});
    }
    headersToUse.push({title: 'Status', sortKey: 'accountStatusOk', className: 'text-center'});
    if (!readOnly) {
      headersToUse.push({title: 'Edit', className: 'text-center'});
    }
    return headersToUse;
  };

  const tableProps = {
    headers: buildHeaders(),
    initialSort: {sortKey: 'firstName', direction: 'asc' as const},
    items: users,
    noResultsMessage: messages.NO_USERS_FOUND,
    resultLimit: resultLimit,
    renderRow: (user: User) => {
      return (
        <UsersTableRow user={user}
                       readOnly={readOnly}
                       key={user.id}
                       onSettingsClick={handleSettingsClick}/>
      );
    }
  };

  return (
    <CustomTable {...tableProps}/>
  );
};

export default memo(UsersTable);