import {useCallback, useMemo, useRef, useState} from 'react';
import {Button, Col, Form, FormText, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import {Formik, FormikHelpers, FormikProps} from 'formik';

import {FormikInput} from '@reasoncorp/kyber-js';

import {passwordApi} from '../../api';
import {ForgotPasswordFormFields} from '../../types';
import {forgotPasswordFormSchema} from '../../schema';

type Props = {
  isOpen: boolean
  onToggle: () => void
}

const ForgotPasswordModal = ({
                               isOpen,
                               onToggle
                             }: Props) => {
  const resultEl = useRef<HTMLParagraphElement>(null);
  const [resetResult, setResetResult] = useState({performed: false, error: false});
  const initialValues: ForgotPasswordFormFields = useMemo(() => ({
    email: ''
  }), []);

  const handleToggle = useCallback((formikProps: FormikProps<ForgotPasswordFormFields>) => {
    onToggle();
    // Clear form with delay for cleaner animation
    setTimeout(() => {
      // clear form on close
      formikProps.resetForm();
      // clear last result to reset to initial state.
      setResetResult({performed: false, error: false});
    }, 250);
  }, [
    onToggle
  ]);

  const handleSubmit = useCallback(async (values: ForgotPasswordFormFields, formikHelpers: FormikHelpers<ForgotPasswordFormFields>) => {
    try {
      await passwordApi.forgotPassword(values);
      formikHelpers.setSubmitting(false);
      setResetResult({performed: true, error: false});
    } catch (error) {
      setResetResult({performed: true, error: true});
    }
    // Focus the message as part of ADA requirements
    resultEl?.current?.focus();
  }, []);

  return (
    <Formik initialValues={initialValues}
            validationSchema={forgotPasswordFormSchema}
            enableReinitialize={true}
            onSubmit={handleSubmit}>
      {(formikProps) => (
        <Modal isOpen={isOpen}
               backdrop="static"
               autoFocus={false}
               toggle={() => handleToggle(formikProps)}>
          <ModalHeader toggle={() => handleToggle(formikProps)}>Forgot Password</ModalHeader>
          {!resetResult.performed ?
            <Form onSubmit={formikProps.handleSubmit} autoComplete="off">
              <ModalBody>
                <Row>
                  <Col>
                    <FormText color="muted">
                      Please enter your single sign on email address to receive a link to reset your password.
                    </FormText>
                    <FormikInput name="email"
                                 labelText="Email Address"
                                 autoFocus
                                 disabled={formikProps.isSubmitting}/>
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                <Button type="submit"
                        color="success"
                        disabled={!formikProps.dirty || !formikProps.isValid || formikProps.isSubmitting}>
                  Reset Password
                </Button>
                <Button color="secondary"
                        onClick={() => handleToggle(formikProps)}
                        disabled={formikProps.isSubmitting}>
                  Cancel
                </Button>
              </ModalFooter>
            </Form>
            :
            <>
              <ModalBody>
                <p className={`text-center font-weight-bold ${!resetResult.error ? 'text-success' : 'text-danger'}`}
                   ref={resultEl}
                   tabIndex={0}>
                  {!resetResult.error ? 'Request Submitted' : 'Request Unsuccessful'}
                </p>
              </ModalBody>
              <ModalFooter>
                <Button color="secondary"
                        onClick={() => handleToggle(formikProps)}>
                  Ok
                </Button>
              </ModalFooter>
            </>
          }
        </Modal>
      )}
    </Formik>
  );
};

export default ForgotPasswordModal;