import {memo, useCallback, useMemo, useState} from 'react';
import {Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import {Formik, FormikErrors, FormikProps, FormikState} from 'formik';

import {
  ConfirmationModal,
  FormikDatePicker,
  FormikInput,
  FormikRadioGroup,
  FormikSelect,
  useAlerts,
  User
} from '@reasoncorp/kyber-js';

import {userApi} from '../api';
import * as messages from '../messages';
import {AddUserFormFields} from '../types/forms';
import {addUserFormSchema} from '../schema';
import branding from '../branding';

type Props = {
  isOpen: boolean
  onToggle: (user?: User) => void
}

type FormikActions = {
  setSubmitting: (isSubmitting: boolean) => void
  setErrors: (errors: FormikErrors<AddUserFormFields>) => void
  resetForm: (nextState?: Partial<FormikState<AddUserFormFields>> | undefined) => void
}

const AddUserModal = ({
                        isOpen,
                        onToggle
                      }: Props) => {
  const {showErrorAlert} = useAlerts();
  const [isAddingExistingUserModalOpen, setIsAddingExistingUserModalOpen] = useState(false);

  const initialValues: AddUserFormFields = useMemo(() => ({
    firstName: '',
    lastName: '',
    username: '',
    certifiedUser: branding.certificationLevels.length === 0 ? 'false' : '',
    certificationLevel: '',
    certificationNumber: '',
    certificationAttainedDate: null
  }), []);

  const createUser = useCallback(async (values: AddUserFormFields, formikHelpers: FormikActions) => {
    try {
      const user = await userApi.create(values);
      onToggle(user);
      formikHelpers.resetForm();
    } catch (error: any) {
      const errorWithType = error as {status: number, validationMessages: FormikErrors<AddUserFormFields>};
      if (errorWithType.status === 422 && errorWithType.validationMessages) {
        // Will come back from the API by virtue of Spring validation messages
        formikHelpers.setErrors(errorWithType.validationMessages);
      } else {
        showErrorAlert(messages.USER_ADD_FAILED);
      }
    } finally {
      formikHelpers.setSubmitting(false);
    }
  }, [
    onToggle,
    showErrorAlert
  ]);

  const handleSubmit = useCallback(async (values: AddUserFormFields,
                                          formikHelpers: FormikActions) => {
    try {
      const doesDeletedUserExist = await userApi.checkExistingDeletedUser(values);
      if (doesDeletedUserExist) {
        setIsAddingExistingUserModalOpen(true);
      } else {
        await createUser(values, formikHelpers);
      }
    } catch (e) {
      showErrorAlert(messages.USER_ADD_FAILED);
      setIsAddingExistingUserModalOpen(false);
    }
  }, [
    createUser,
    showErrorAlert
  ]);

  const handleReinstateUser = useCallback(async (values: AddUserFormFields,
                                                 formikHelpers: FormikActions) => {
    try {
      await createUser(values, formikHelpers);
    } catch (e) {
      showErrorAlert(messages.USER_ADD_FAILED);
    }
    setIsAddingExistingUserModalOpen(false);
  }, [
    createUser,
    showErrorAlert
  ]);

  const handleCancel = useCallback((formikProps: FormikProps<AddUserFormFields>) => {
    setIsAddingExistingUserModalOpen(false);
    formikProps.resetForm();
    onToggle();
  }, [
    onToggle
  ]);

  return (
    <Formik initialValues={initialValues}
            validationSchema={addUserFormSchema}
            onSubmit={(values, {resetForm, setErrors, setSubmitting}) => handleSubmit(
              values,
              {
                setSubmitting,
                setErrors,
                resetForm
              }
            )}>
      {(formikProps) => (<>
          <Modal isOpen={isOpen && !isAddingExistingUserModalOpen}
                 backdrop="static"
                 autoFocus={false}
                 toggle={() => handleCancel(formikProps)}>
            <ModalHeader toggle={() => handleCancel(formikProps)}>Add User</ModalHeader>
            <Form onSubmit={formikProps.handleSubmit} autoComplete="off">
              <ModalBody>
                <Row>
                  <Col>
                    <FormikInput name="firstName"
                                 labelText="First Name"
                                 autoFocus
                                 aria-required="true"/>
                  </Col>
                  <Col>
                    <FormikInput name="lastName"
                                 labelText="Last Name"
                                 aria-required="true"/>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormikInput name="username"
                                 labelText="Email Address"
                                 aria-required="true"/>
                  </Col>
                </Row>
                {branding.certificationLevels.length !== 0 &&
                  <Row>
                    <Col>
                      <FormikRadioGroup name="certifiedUser"
                                        inline
                                        radioButtons={[
                                          {labelText: 'Certified User', value: 'true'},
                                          {labelText: 'Uncertified User', value: 'false'}
                                        ]}/>
                    </Col>
                  </Row>
                }
                {formikProps.values.certifiedUser === 'true' &&
                  <Row>
                    <Col lg="12">
                      <FormikSelect name="certificationLevel"
                                    labelText="Certification Level"
                                    aria-required="true">
                        <option value="">Select</option>
                        <option value="MMAO">MMAO</option>
                        <option value="MAAO">MAAO</option>
                        <option value="MCAO">MCAO</option>
                        <option value="MCAT">MCAT</option>
                      </FormikSelect>
                    </Col>
                    <Col lg="6">
                      <FormikInput name="certificationNumber"
                                   labelText="Certification Number"
                                   aria-required="true"/>
                    </Col>
                    <Col lg="6">
                      <FormikDatePicker name="certificationAttainedDate"
                                        labelText="Initial Certification Attained"/>
                    </Col>
                  </Row>
                }
              </ModalBody>
              <ModalFooter>
                <Button color="success"
                        className="mr-1"
                        onClick={formikProps.submitForm}
                        disabled={!formikProps.dirty || !formikProps.isValid || formikProps.isSubmitting}>
                  Add
                </Button>
                <Button color="secondary"
                        onClick={() => handleCancel(formikProps)}
                        disabled={formikProps.isSubmitting}>
                  Cancel
                </Button>
              </ModalFooter>
            </Form>
          </Modal>

          <ConfirmationModal isOpen={isAddingExistingUserModalOpen}
                             title="Add User"
                             confirmButtonText="Yes"
                             confirmCallback={() => handleReinstateUser(
                               formikProps.values,
                               {
                                 resetForm: formikProps.resetForm,
                                 setSubmitting: formikProps.setSubmitting,
                                 setErrors: formikProps.setErrors
                               }
                             )}
                             cancelButtonText="No"
                             cancelCallback={() => setIsAddingExistingUserModalOpen(false)}>
            This email address was previously linked to a MiSuite user, would you like to reinstate this user? If no, please enter a new email address.
          </ConfirmationModal>
        </>
      )}
    </Formik>
  );
};

export default memo(AddUserModal);