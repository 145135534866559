import {ssoApi} from './apiUtils';
import {AuditReportFormFields} from '../types/forms';

export const downloadUserReport = () => ssoApi.restApiHelper.postWithBlobResponse(
  '/reports/users'
);

export const downloadAuditReport = (auditReportRequest: AuditReportFormFields) => ssoApi.restApiHelper.postWithBlobResponse(
  '/reports/audit-events',
  {
    body: JSON.stringify(auditReportRequest)
  }
);