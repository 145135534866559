import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Badge, Button, Form, FormGroup} from 'reactstrap';
import {Formik, FormikHelpers} from 'formik';

import {FormikPasswordInput} from '@reasoncorp/kyber-js';

import * as messages from '../../messages';
import {authenticationApi, userApi} from '../../api';
import {useSsoAppContext} from '../../hooks';
import {ResetPasswordFormFields} from '../../types';
import {resetPasswordFormSchema} from '../../schema';
import config from '../../config';

type Props = {
  code: string,
  onUnknownDevice: () => void
}

const ResetPasswordLoginForm = ({
                                  code,
                                  onUnknownDevice
                                }: Props) => {
  const navigate = useNavigate();
  const {setCurrentUser} = useSsoAppContext();
  const errorEl = useRef<HTMLParagraphElement>(null);
  const [serverError, setServerError] = useState(messages.CREATE_PASSWORD);
  const resetLoginFormInitialValues: ResetPasswordFormFields = useMemo(() => ({
    code,
    password: '',
    confirmPassword: ''
  }), [
    code
  ]);

  const handleSubmit = useCallback(async (values: ResetPasswordFormFields,
                                          formikHelpers: FormikHelpers<ResetPasswordFormFields>) => {
    try {
      await authenticationApi.resetPasswordLogin(values);
      const currentUser = await userApi.currentUser();
      setCurrentUser(currentUser);
      navigate('/dashboard');
    } catch (error: any) {
      const errorWithType = error as {message?: string, exception?: string | string[]};
      formikHelpers.setSubmitting(false);
      // Fetch will throw a TypeError if there was a network issue
      if (error instanceof TypeError) {
        setServerError(messages.UNABLE_TO_CONNECT_SERVICE);
      } else if (errorWithType.exception && errorWithType.exception.includes('RememberMeAuthenticationException')) {
        onUnknownDevice();
      } else if (error.message) {
        setServerError(error.message);
      } else {
        setServerError(messages.UNKNOWN_LOGIN_FAILURE);
      }
      // Focus the error message as part of ADA requirements
      errorEl?.current?.focus();
    }
  }, [
    navigate,
    onUnknownDevice,
    setCurrentUser
  ]);

  useEffect(() => {
    // Focus the error message as part of ADA requirements
    errorEl?.current?.focus();
  }, []);

  const loginErrorClassName = useMemo(() => {
    return `text-center text-danger p-2 ${serverError ? 'login-error' : ''}`;
  }, [
    serverError
  ]);

  const headerClassName = useMemo(() => {
    return `h4 mt-5 text-center ${serverError ? 'mb-3' : 'mb-4'}`;
  }, [
    serverError
  ]);

  return <div className="ResetPasswordLoginForm">
    <h3 className={headerClassName}>
      Create Password
      {config.envName !== 'prod' &&
        <Badge color="warning" className="text-uppercase ml-2">
          {config.envName}
        </Badge>
      }
    </h3>
    <p className={loginErrorClassName}
       ref={errorEl}
       tabIndex={0}>
      {serverError}
    </p>
    <Formik initialValues={resetLoginFormInitialValues}
            validationSchema={resetPasswordFormSchema}
            onSubmit={handleSubmit}>
      {formikProps => (
        <Form onSubmit={formikProps.handleSubmit}>
          <FormikPasswordInput name="password"
                               autoComplete="new-password"
                               labelText="Create New Password"/>
          <FormikPasswordInput name="confirmPassword"
                               autoComplete="new-password"
                               labelText="Confirm New Password"/>
          <FormGroup className="text-center">
            <Button type="submit"
                    color="primary"
                    disabled={!formikProps.dirty || !formikProps.isValid || formikProps.isSubmitting}>
              Login
            </Button>
          </FormGroup>
        </Form>
      )}
    </Formik>
  </div>;
};

export default ResetPasswordLoginForm;