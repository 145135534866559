import {ReactNode, useCallback, useMemo, useState} from 'react';
import {Card, CardBody, CardHeader, Col, Collapse, Container, Row} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconProp} from '@fortawesome/fontawesome-svg-core';

type QuestionCardProps = {
  section: FaqSection
  headerText: string
  children: ReactNode
  icon: IconProp
}

type FaqSection = 'section1' | 'section2' | 'section3' | 'section4'

const Faq = () => {
  const [sectionOpenState, setSectionOpenState] = useState({
    section1: true,
    section2: false,
    section3: false,
    section4: false
  });

  const toggleSection = useCallback((section: FaqSection) => {
    setSectionOpenState({...sectionOpenState, [section]: !sectionOpenState[section]});
  }, [
    sectionOpenState
  ]);

  const QuestionCard = useMemo(() => ({
                                        headerText,
                                        children,
                                        section,
                                        icon
                                      }: QuestionCardProps) => {
    const isOpen = sectionOpenState[section];
    const cardClassName = isOpen ? 'faq-section-open' : 'faq-section-closed';

    return <Card className={cardClassName}>
      <CardHeader className="cursor-pointer"
                  onClick={() => toggleSection(section)}>
        <Row>
          <Col md={{size: 10}}>
            <FontAwesomeIcon icon={icon} className="mr-3 text-primary"/>
            {headerText}
          </Col>
          <Col md={{size: 2}} className="d-flex justify-content-end">
            <FontAwesomeIcon icon={isOpen ? 'angle-up' : 'angle-down'} className="mt-1 text-primary"/>
          </Col>
        </Row>
      </CardHeader>
      <Collapse isOpen={isOpen}>
        <CardBody>
          {children}
        </CardBody>
      </Collapse>
    </Card>;
  }, [
    sectionOpenState,
    toggleSection
  ]);

  return (
    <Container fluid className="Faq">
      <Row className="text-white Faq-header">
        <Col sm={{size: 12}}>
          <h1 className="h1 text-center text-nowrap">
            Frequently Asked Questions
          </h1>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col sm={{size: 12}}
             lg={{size: 8, offset: 2}}>
          <Row>
            <Col className="col-12 mb-4">
              <QuestionCard section="section1"
                            icon="user-times"
                            headerText="My account is disabled">
                Send an email to <a className="font-weight-bold" href="mailto:treas-misuitehelp@michigan.gov">treas-misuitehelp@michigan.gov</a> to regain access to your MiSuite account.
                <br/>
                <br/>
                In order not to be disabled:
                <ul>
                  <li>
                    <strong>Equalization Directors</strong> must log into their account every <strong>90</strong> days
                  </li>
                  <li>
                    <strong>State Administrators</strong> must log into their account every <strong>60</strong> days
                  </li>
                </ul>
                Reminder emails will go out multiple times before your account becomes disabled.
              </QuestionCard>
            </Col>
            <Col className="col-12 mb-4">
              <QuestionCard section="section2"
                            icon="user-lock"
                            headerText="My account is locked">
                <ol className="pl-3">
                  <li className="mb-3">
                    Click "Forgot Password" on Login screen to receive a password reset link. An email will be sent to the email address linked to your MiSuite account from our <strong>no-reply@misuite.app</strong> address.
                  </li>
                  <li className="mb-3">
                    Check your junk/spam folder if you have not received a password reset link and/or verification email.
                  </li>
                  <li className="mb-3">
                    Add <strong>no-reply@misuite.app</strong> to your contact list to avoid the reset email being marked as spam going forward.
                  </li>
                  <li>
                    If you did not receive an email, ask your IT coordinator to "allow list" the <strong>no-reply@misuite.app</strong> email address.
                  </li>
                </ol>
              </QuestionCard>
            </Col>
            <Col className="col-12 mb-4">
              <QuestionCard section="section3"
                            icon="exclamation-circle"
                            headerText="Received a failure popup message when loading the page">
                    <span className="mb-2 d-inline-block">
                      Clear your browser cache and reload the page.
                    </span>
                <ul>
                  <li>To clear your cache within Google Chrome:</li>
                  <ul>
                    <li>On the browser toolbar, click the Chrome 3 dots at the top right of screen</li>
                    <li>Select Settings</li>
                    <li>Select Privacy and security</li>
                    <li>Select Clear browsing data</li>
                    <li>Under the Basic tab, make sure “Cached images and files” is checked</li>
                    <li>Select Clear data</li>
                    <li>Return to MiSuite and reload the page. You might be directed to sign in again.</li>
                  </ul>
                </ul>
              </QuestionCard>
            </Col>
            <Col className="col-12">
              <QuestionCard section="section4"
                            icon="question-circle"
                            headerText="Received a ‘404 Error’ message when trying to access the Login Page">
                Your IT coordinator will need to “allow list” the MiSuite websites. <br/>
                Email <a className="font-weight-bold" href="mailto:treas-misuitehelp@michigan.gov">treas-misuitehelp@michigan.gov</a> for details.
                Please include screenshots of any messages you are receiving.
              </QuestionCard>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col sm={{size: 12}} lg={{size: 8, offset: 2}}>
          <p className="small text-center mt-3" role="region">
            Warning! This system contains government information. By accessing and using this system, you are consenting
            to monitoring for any purpose, including law enforcement. Unauthorized use of, or access to, this computer
            system may subject you to State and Federal criminal prosecution and penalties as well as civil penalties.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default Faq;