import {useCallback, useMemo, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {Card, Col, Container, Row} from 'reactstrap';
import {LoginForm, NewPasswordLoginForm, ResetPasswordLoginForm, VerifyDeviceLoginForm} from '../components/login';
import branding from '../branding';

type LoginType = 'LOGIN' | 'NEW_PASSWORD_LOGIN' | 'RESET_PASSWORD_LOGIN' | 'VERIFY_DEVICE_LOGIN';

const Login = () => {
  const location = useLocation();
  const urlSearchParams = useMemo(() => new URLSearchParams(location.search), [location]);
  const [loginToDisplay, setLoginToDisplay] = useState<LoginType>(!urlSearchParams.has('code') ? 'LOGIN' : 'RESET_PASSWORD_LOGIN');
  const [lastCredentials, setLastCredentials] = useState({username: '', password: ''});

  const handleUnknownDevice = useCallback(() => {
    setLoginToDisplay('VERIFY_DEVICE_LOGIN');
  }, [
    setLoginToDisplay
  ]);

  const handlePasswordExpired = useCallback((lastCredentials: {username: string, password: string}) => {
    if (lastCredentials) {
      setLastCredentials(lastCredentials);
    }
    setLoginToDisplay('NEW_PASSWORD_LOGIN');
  }, []);

  const renderLogin = useMemo(() => () => {
    switch (loginToDisplay) {
      case 'LOGIN':
        return <LoginForm onUnknownDevice={handleUnknownDevice}
                          onPasswordExpired={handlePasswordExpired}/>;
      case 'NEW_PASSWORD_LOGIN':
        return <NewPasswordLoginForm lastCredentials={lastCredentials}
                                     onUnknownDevice={handleUnknownDevice}/>;
      case 'RESET_PASSWORD_LOGIN':
        return <ResetPasswordLoginForm code={urlSearchParams.get('code')!}
                                       onUnknownDevice={handleUnknownDevice}/>;
      case 'VERIFY_DEVICE_LOGIN':
        return <VerifyDeviceLoginForm/>;
    }
  }, [
    loginToDisplay,
    handlePasswordExpired,
    handleUnknownDevice,
    lastCredentials,
    urlSearchParams
  ]);

  return (
    <Container fluid className="Login">
      <Row>
        <Col sm={{size: 12}}
             md={{size: 6, offset: 3}}
             lg={{size: 6, offset: 3}}
             xl={{size: 7}}
             className="ml-xl-auto mr-xl-auto Login-Centered">
          <div>
            <Row className="mt-2 login-row">
              <Col xs={{size: 12}}
                   sm={{size: 12}}
                   md={{size: 12}}
                   lg={{size: 12}}
                   xl={{size: 7}}
                   className="mt-5 text-center info-column">
                <div className="welcome-text">
                  <h1 className="font-weight-bold text-white">
                    Welcome to
                  </h1>
                  <h2 className="font-weight-bold mb-4 text-white">
                    {branding.displayName}
                  </h2>
                </div>
              </Col>
              <Col xs={{size: 12}}
                   sm={{size: 12}}
                   md={{size: 12}}
                   lg={{size: 12}}
                   xl={{size: 5}}
                   className="form-column mb-2 mt-xl-5 mb-xl-3 pr-xl-0">
                <Card className="p-4 mt-4 mb-4">
                  {renderLogin()}
                </Card>
              </Col>
            </Row>
            <Row className="mt-5 text-center">
              <Col
                className="pl-xl-4 pr-xl-4">
                <p className="small text-white" role="region">
                  Warning! This system contains government information. By accessing and using this system, you are consenting
                  to monitoring for any purpose, including law enforcement. Unauthorized use of, or access to, this computer
                  system may subject you to State and Federal criminal prosecution and penalties as well as civil penalties.
                </p>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;