import {createRoot} from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';

import {AlertsProvider} from '@reasoncorp/kyber-js';

import './scss/index.scss';
import config from './config';
import branding from './branding';
import {SsoAppProvider} from './contexts';
import App from './App';

if (config.envName !== 'prod') {
  document.title = `${config.envName.toUpperCase()} - ${branding.title}`;
} else {
  document.title = branding.title;
}

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <BrowserRouter>
    <SsoAppProvider>
      <AlertsProvider>
        <App/>
      </AlertsProvider>
    </SsoAppProvider>
  </BrowserRouter>
);