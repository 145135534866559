import {memo, useCallback, useState} from 'react';
import {Container, Row} from 'reactstrap';

import {BreadcrumbsNav, ProgressIndicator, ProgressModal, useAlerts} from '@reasoncorp/kyber-js';

import {useSsoAppContext} from '../hooks';
import {miSuiteReportApi, reportApi} from '../api';
import * as messages from '../messages';
import {AuditReportModal, ReportCard} from '../components';

const Reports = () => {
  const {
    isAdmin,
    isAuditor,
    isMegAdmin,
    isCertsAdmin,
    hasMiMessagingAuditReportAccess
  } = useSsoAppContext();
  const {showSuccessAlert, showErrorAlert} = useAlerts();
  const [auditReportModalIsOpen, setAuditReportModalIsOpen] = useState(false);
  const [loadingState, setLoadingState] = useState({
    loading: false,
    loadError: false,
    exportingReport: false,
    progressModalTitle: ''
  });

  const handleToggleAuditReportModal = useCallback((response?: Response) => {
    if (response) {
      showSuccessAlert(messages.REPORT_EXPORT_SUCCESSFUL);
    }
    setAuditReportModalIsOpen(false);
  }, [showSuccessAlert]);

  const handleDownloadReport = useCallback(async (progressModalTitle: string, apiRequest: () => Promise<Response>) => {
    setLoadingState(prevLoadingState => ({
      ...prevLoadingState,
      exportingReport: true,
      progressModalTitle
    }));

    try {
      await apiRequest();
      showSuccessAlert(messages.REPORT_EXPORT_SUCCESSFUL);
    } catch (e) {
      showErrorAlert(messages.REPORT_EXPORT_FAILED);
    } finally {
      setLoadingState(prevLoadingState => ({...prevLoadingState, exportingReport: false}));
    }
  }, [
    showSuccessAlert,
    showErrorAlert
  ]);

  const handleDownloadUserProfileAndRoleReport = useCallback(() => handleDownloadReport(
    'Generating User Profile & Role Report',
    reportApi.downloadUserReport
  ), [
    handleDownloadReport
  ]);

  const handleDownloadMegUserReport = useCallback(() => handleDownloadReport(
    'Generating MEG User Report',
    miSuiteReportApi.downloadMegUserReport
  ), [
    handleDownloadReport
  ]);

  const handleDownloadLocalUnitFactorReport = useCallback(() => handleDownloadReport(
    'Generating Local Unit Factor Report',
    miSuiteReportApi.downloadLocalUnitFactorReport
  ), [
    handleDownloadReport
  ]);

  const handleDownload4022VerificationReport = useCallback(() => handleDownloadReport(
    'Generating 4022 Verification Report',
    miSuiteReportApi.downloadForm4022VerificationReport
  ), [
    handleDownloadReport
  ]);

  const handleDownloadCertificationVerificationReport = useCallback(() => handleDownloadReport(
    'Generating Certification Verification Report',
    miSuiteReportApi.downloadForm4022CertificationReport
  ), [
    handleDownloadReport
  ]);

  const handleDownloadMiMessagingAuditReport = useCallback(() => handleDownloadReport(
    'Generating MiMessaging Audit Report',
    miSuiteReportApi.downloadMiMessagingAuditReport
  ), [
    handleDownloadReport
  ]);

  return <Container fluid className="Reports">
    {loadingState.loading && <ProgressIndicator/>}
    {!loadingState.loading &&
      <>
        <BreadcrumbsNav breadcrumbs={[{text: 'Reports', active: true}]}/>
        <Row>
          {isAuditor && <ReportCard key="userAuditReport"
                                    title="User Audit Report"
                                    description="Report of user actions for a given date range."
                                    onDownloadClick={() => setAuditReportModalIsOpen(true)}
          />}
          {isAdmin && <ReportCard key="userProfileAndRoleReport"
                                  title="User Profile and Role Report"
                                  description="Report of all MiSuite users profile information and roles. Includes active and inactive users."
                                  onDownloadClick={handleDownloadUserProfileAndRoleReport}
          />}
          {(isAdmin || isMegAdmin) && <ReportCard key="megUserReport"
                                                  title="MEG User Report"
                                                  description="Report of current MEG State, County, and Local Unit users."
                                                  onDownloadClick={handleDownloadMegUserReport}
          />}
          {isMegAdmin && <ReportCard key="localUnitFactorReport"
                                       title="Local Unit Factor Report"
                                       description="Report displaying local units that received a factor on the MEG 4023 by classification."
                                       onDownloadClick={handleDownloadLocalUnitFactorReport}
          />}
          {(isAdmin || isMegAdmin || isCertsAdmin) && <ReportCard key="certificationVerificationReport"
                                                                  title="Certification Verification Report"
                                                                  description="Report displaying if AOR met SEV limit and commercial real and personal, industrial real and personal, utility personal and special act limit"
                                                                  onDownloadClick={handleDownloadCertificationVerificationReport}
          />}
          {(isAdmin || isMegAdmin || isCertsAdmin) && <ReportCard key="4022VerificationReport"
                                                                  title="4022 Verification Report"
                                                                  description="Report displaying if AOR who submitted the local unit L-4022 met the required certification"
                                                                  onDownloadClick={handleDownload4022VerificationReport}
          />}
          {hasMiMessagingAuditReportAccess && <ReportCard key="miMimessagingAuditReport"
                                                          title="MiMessaging Audit Report"
                                                          description="Report of all MiMessaging System Generated Communications."
                                                          onDownloadClick={handleDownloadMiMessagingAuditReport}
          />}
          {isAuditor &&
            <AuditReportModal isOpen={auditReportModalIsOpen}
                              onToggle={handleToggleAuditReportModal}/>
          }
          <ProgressModal isOpen={loadingState.exportingReport}
                         title={loadingState.progressModalTitle}
                         content="Report is being generated. Please do not refresh the page, as this could take a few moments."/>
        </Row>
      </>
    }
  </Container>;
};

export default memo(Reports);