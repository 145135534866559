import * as Yup from 'yup';
import * as messages from '../messages';

export default Yup.object().shape({
  username: Yup.string(),
  currentPassword: Yup.string().required(messages.REQUIRED),
  password: Yup.string().required(messages.REQUIRED),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], messages.PASSWORDS_MUST_MATCH)
    .required(messages.REQUIRED)
});