import {ssoApi} from './apiUtils';
import {UpdatePasswordFormFields} from '../types';

export const forgotPassword = (forgotPasswordRequest: {email: string}) => ssoApi.restApiHelper.postWithEmptyResponse(
  'password-reset-requests',
  {
    body: JSON.stringify(forgotPasswordRequest)
  }
);

export const updateCurrentUserPassword = (passwordUpdateRequest: UpdatePasswordFormFields) => ssoApi.restApiHelper.putWithEmptyResponse(
  'users/current-user/password',
  {
    body: JSON.stringify(passwordUpdateRequest)
  }
);

export const reset = (userId: number) => ssoApi.restApiHelper.postWithEmptyResponse(
  `users/${userId}/password`
);