const branding = {
  misuite: {
    name: 'MISUITE',
    displayName: 'MiSuite',
    title: 'MiSuite - Single Sign On',
    organizationName: 'The Department of Treasury',
    certificationLevels: ['MMAO', 'MAAO', 'MCAO', 'MCAT'],
    userSettingsEnabled: true
  },
  prosuite: {
    name: 'PROSUITE',
    displayName: 'ProSuite',
    title: 'ProSuite - Single Sign On',
    organizationName: 'The Department of Treasury',
    certificationLevels: [],
    userSettingsEnabled: false
  }
};

const getBranding = () => {
  const env = process.env.REACT_APP_SSO_BRANDING;
  if (env === null || env === undefined) {
    return window.location.hostname.indexOf('prosuite') > -1 ? 'prosuite' : 'misuite';
  } else {
    return env;
  }
};

export default getBranding() === 'prosuite' ? branding.prosuite : branding.misuite;